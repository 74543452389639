body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.outputMain {
  position: absolute;
  z-index: 0;
  background-color: white;
}

.MuiCard-root {
  display: flex;
  flex-direction: column;
  width: 70vw;
  margin-left: 15vw;
  margin-right: 15vw;
  margin-top: 1.5vh;
  height: 97vh;
}

@media screen and (max-width: 768px) {
  .MuiCard-root {
    width: 100vw;
    margin-left: 0vw;
    margin-right: 0vw;
    margin-top: 0vh;
    height: 100vh;
  }
}

.MuiCardActions-root {
  margin-top: auto;
}
